import React from 'react'
import { FormattedMessage, FormattedHTMLMessage } from "gatsby-plugin-intl"
import scrollTo from 'gatsby-plugin-smoothscroll'

import './intro.scss'

export default () =>
  <section className="intro">
    <div className="container">
      <h1>
      <FormattedMessage defaultMessage="Hi, I am " id="intro.headline" />
      <button onClick={() => scrollTo('#about')}><FormattedMessage defaultMessage="Matt" id="intro.name" /></button>
       </h1>
      <h2>
        <span><span>Front-end</span> developer</span>
        <FormattedHTMLMessage defaultMessage="<span>Graphic</span> designer" id="intro.designer" />
        <FormattedHTMLMessage defaultMessage="<span>Music</span>ian" id="intro.musician" />
      </h2>
    </div>
  </section>