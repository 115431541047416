import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from "gatsby-image"
import { TechLogo } from "../../../Icons"
import { FormattedHTMLMessage } from "gatsby-plugin-intl"

import './about.scss'

export default () => {
    const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "avatar.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
    return (
        <section className="about" id="about">
            <div className="container">
                <div className="about__headline">
                    <FormattedHTMLMessage defaultMessage="About me" id="about.headline" tagName="h2"/>
                </div>
                <div className="about__content">
                    <Img className="about__avatar" fluid={data.placeholderImage.childImageSharp.fluid} />
                    <FormattedHTMLMessage defaultMessage="Hi, my name is <b>Matěj Petříček</b> and I'm originally from <b>Czech Republic</b>. Since 2013 I have a girlfriend in <b>Croatia</b> and I'm often travelling between those two countries. I'm passionate about <b>technologies</b>, <b>music</b> and <b>bicycles</b>. Because of that I have always my laptop, violin and bicycle repair tools on the road." id="about.content" tagName="p"/>
                    
                </div>

                <FormattedHTMLMessage defaultMessage="Technologies I use" id="about.technologies" tagName="h3"/>
                <div className="about__logos">
                    <a rel="noopener noreferrer" href="https://affinity.serif.com/en-gb/" target="_blank"><TechLogo iconType="affinity" /></a>
                    <a rel="noopener noreferrer" href="https://www.adobe.com/products/illustrator.html?promoid=PGRQQLFS&mv=other" target="_blank"><TechLogo iconType="ai" /></a>
                    <a rel="noopener noreferrer" href="https://www.adobe.com/products/photoshop.html?promoid=PC1PQQ5T&mv=other" target="_blank"><TechLogo iconType="ps" /></a>
                    <a rel="noopener noreferrer" href="https://www.w3.org/TR/2001/WD-css3-roadmap-20010523/" target="_blank"><TechLogo iconType="css" /></a>
                    <a rel="noopener noreferrer" href="https://www.html5rocks.com/en/index.html" target="_blank"><TechLogo iconType="html" /></a>
                    <a rel="noopener noreferrer" href="https://www.javascript.com/" target="_blank"><TechLogo iconType="js" /></a>
                    <a rel="noopener noreferrer" href="https://jquery.com/" target="_blank"><TechLogo iconType="jquery" /></a>
                    <a rel="noopener noreferrer" href="https://secure.php.net/" target="_blank"><TechLogo iconType="php" /></a>
                    <a rel="noopener noreferrer" href="https://getbootstrap.com/" target="_blank"><TechLogo iconType="bootstrap" /></a>
                    <a rel="noopener noreferrer" href="https://reactjs.org/" target="_blank"><TechLogo iconType="react" /></a>
                    <a rel="noopener noreferrer" href="https://www.gatsbyjs.org/" target="_blank"><TechLogo iconType="gatsby" /></a>
                    <a rel="noopener noreferrer" href="https://wordpress.org/" target="_blank"><TechLogo iconType="wp" /></a>
                    <a rel="noopener noreferrer" href="https://www.joomla.org/" target="_blank"><TechLogo iconType="joomla" /></a>
                    <a rel="noopener noreferrer" href="https://octobercms.com/" target="_blank"><TechLogo iconType="octobercms" /></a>
                    <a rel="noopener noreferrer" href="https://getgrav.org" target="_blank"><TechLogo iconType="grav" /></a>
                    <a rel="noopener noreferrer" href="https://code.visualstudio.com/" target="_blank"><TechLogo iconType="vs" /></a>
                </div>
            </div>

        </section>

    )
}
