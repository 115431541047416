import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { FormattedMessage, FormattedHTMLMessage } from "gatsby-plugin-intl"
import Img from 'gatsby-image'

import './boat.scss'

export default () => {
    const data = useStaticQuery(graphql`
        query {
            file(name: {eq: "boat"}) {
                childImageSharp {
                    fluid (maxWidth: 3000) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `)
    return (
        <section className="boat">
            <Img fluid={data.file.childImageSharp.fluid} />
            <div className="container">
                <div className="boat__content">
                <FormattedMessage tagName="h2" defaultMessage="We are in the same boat!" id="boat.headline"/>
                <FormattedHTMLMessage tagName="p" defaultMessage="<b>My goal</b> is to fulfill <b>your expectations</b> and make <b>successful product</b>. I'm here to help you with <b>my skills</b>. But I need something from you, too. And that is <b>good documentation</b> for your project. If you <b>prepare it smart</b>, everything is going to be smoother and we will <b>avoid heavy seas</b>."
                id="boat.content"/>
                <a href="mailto:matej@petriczech.com"><FormattedMessage defaultMessage="Get in touch" id="getintouch"/></a>
                </div>
            </div>
        </section>
    )
}