import React from 'react'
import { Swipeable } from 'react-swipeable'
import { FormattedMessage } from "gatsby-plugin-intl"
import useWindowSize from '../../../Hooks/UseWindowSize'
import Img from 'gatsby-image'
import VisibilitySensor from 'react-visibility-sensor'
import { Close, Next, TechLogo, Web } from "../../../Icons";

export default props => {
    const [index, setIndex] = React.useState('')
    const [lightBox, setLightBox] = React.useState(false)
    const [selectedCard, setSelectedCard] = React.useState('')
    const [slideSide, setSlideSide] = React.useState(null)
    const [hideContent, setHideContent] = React.useState(false)

    const size = useWindowSize()


    const setCard = (data, index) => {
        setIndex(index)
        setSelectedCard(data[index])
        setLightBox(true)
    }

    const setPrev = () => {
        setSlideSide('left')
        if (index !== 0) {
            setSelectedCard(props.data[index - 1])
            setIndex(index - 1)
        } else {
            setSelectedCard(props.data[props.data.length - 1])
            setIndex(props.data.length - 1)
        }
    }
    const setNext = () => {
        setSlideSide('right')
        if (index < props.data.length - 1) {
            setIndex(index + 1)
            setSelectedCard(props.data[index + 1])

        } else {
            setIndex(0)
            setSelectedCard(props.data[0])
        }

    }

    React.useEffect(
        () => {
            window.addEventListener('popstate', (event) => {
                if (event.state) {
                    setLightBox(false)
                } else {
                    window.history.pushState({ name: "browserBack" }, "on browser back click", window.location.href);
                    window.history.pushState({ name: "browserBack" }, "on browser back click", window.location.href);
                }
            }, false)

        })

    React.useEffect(
        () => {
            if (lightBox === true) {
                document.body.classList.add('hideOverflow')
            } else {
                document.body.classList.remove('hideOverflow')
            }
        }, [lightBox]
    )



    const content = (data, opener, hideContentSwitch) =>
        <div className={`item__content ${hideContent ? 'item__content--hide' : ''}`}>
            <button
                className="item__open"
                onClick={hideContentSwitch ? () => setHideContent(!hideContent) : opener}>
                <Next />
            </button>
            {data.title &&
                <div 
                    onClick={opener && opener}  
                    onKeyDown={opener && opener}
                    role="button"
                    tabIndex={0}
                    className="item__headline">
                    <h4>{data.title}</h4>
                </div>
            }
            {data.tags &&
                <div className="item__tags">
                    {data.tags.map(tag =>
                        <h5 key={tag}>{tag}</h5>
                    )}
                </div>
            }

            {data.technologies &&
                <div className="item__technologies">
                    {data.technologies.map(technology =>
                        <TechLogo
                            key={technology}
                            iconType={technology}
                        />
                    )}
                </div>
            }

            {data.link &&
                <div className="item__link">
                    <a href={`https://${data.link}`} target="__blanl" rel="noopener noreferrer">
                        <Web />
                        <FormattedMessage
                            tagName='span'
                            defaultMessage="Open website"
                            id="portfolio.openWebsite" />
                    </a>
                </div>
            }
        </div>
    return (
        <div className={`portfolio__${props.name}`}>
            <div className="portfolio__subheadline">
                <h3>{props.icon} {props.headline}</h3>
            </div>
            {props.data && props.data.map((portfolio, index) => {

                return (
                    <div className="portfolio__item" key={portfolio.title}>

                        <VisibilitySensor>
                            {({ isVisible }) =>
                                <div
                                    className={`item__background ${isVisible ? 'item__background--visible' : ''}`}
                                    onClick={() => setCard(props.data, index)}
                                    onKeyDown={() => setCard(props.data, index)}
                                    role="button"
                                    tabIndex={0}>
                                    {portfolio.backgroundMobile ?
                                        <Img
                                            fluid={portfolio.backgroundMobile.childImageSharp.fluid}

                                        />
                                        :
                                        <Img
                                            fluid={portfolio.background.childImageSharp.fluid}

                                        />
                                    }
                                </div>
                            }
                        </VisibilitySensor>


                        {props.previewContent && content(portfolio, () => setCard(props.data, index))}
                    </div>
                )
            })}
            <div className={`portfolio__lightbox portfolio__lightbox--${props.name} ${lightBox ? "portfolio__lightbox--show" : ""}`}>
                {lightBox &&
                    <Swipeable onSwipedLeft={() => setNext()} onSwipedRight={() => setPrev()}>
                        <div

                            className={`item__inner item__inner--${slideSide}`}
                        >
                            <button onClick={() => setLightBox(!lightBox)} className="item__close"><Close /></button>


                            <div
                                className="item__background"
                                onAnimationEnd={() => setSlideSide(null)}>
                                {size.width < 992 && selectedCard.backgroundMobile ?

                                    <Img
                                        fluid={selectedCard.backgroundMobile.childImageSharp.fluid}

                                    />

                                    :
                                    selectedCard.background &&
                                    <Img
                                        key={selectedCard.background.id}
                                        fluid={selectedCard.background.childImageSharp.fluid}

                                        style={{ maxHeight: "100%", position: "absolute" }} />
                                }
                            </div>



                            {content(selectedCard, null, true)}


                            <button
                                className="item__prev"
                                onClick={() => setPrev()}
                            >
                                Previous
                        </button>
                            <button
                                className="item__bottomClose"
                                onClick={() => setLightBox(!lightBox)}
                            >
                                <span>Close</span>
                            </button>
                            <button
                                className="item__next"
                                onClick={() => setNext()}
                            >
                                Next
                        </button>
                        </div>
                    </Swipeable>
                }
            </div>
        </div>
    )
}
