import React from 'react'
import { Trees, Clock, Wallet } from '../../../Icons'
import { FormattedHTMLMessage } from "gatsby-plugin-intl"

import './blocks.scss'

export default () =>
  <section className="blocks">
    <div className="container">
        <div className="blocks__block">
            <Trees />
            <FormattedHTMLMessage defaultMessage="Clean & reusable" id="blocks.block1.headline" tagName="h2"/>
            <FormattedHTMLMessage defaultMessage="Code or design, it doesn't matter.<br />
                Output of my work is<br /> clean and reusable." id="blocks.block1.text" tagName="p"/>
        </div>
        <div className="blocks__block">
            <Clock />
            <FormattedHTMLMessage defaultMessage="On time" id="blocks.block2.headline" tagName="h2"/>
            <FormattedHTMLMessage defaultMessage="I understand how important<br /> deadlines are in today's world.<br /> I follow them strictly." id="blocks.block2.text" tagName="p"/>
            
        </div>
        <div className="blocks__block">
            <Wallet />
            <FormattedHTMLMessage defaultMessage="On budget" id="blocks.block3.headline" tagName="h2"/>
            <FormattedHTMLMessage defaultMessage="It depends only on you, alone.<br />
                Big or small, I'll get it done<br /> within your budget." id="blocks.block3.text" tagName="p"/>
            
        </div>
    </div>
  </section>