import React from "react"
import { graphql } from 'gatsby'

import Layout from "../components/Layout"
import Seo from "../components/Seo"

import Intro from "../components/Pages/Index/Intro"
import Blocks from "../components/Pages/Index/Blocks"
import Portfolio from "../components/Pages/Index/Portfolio"
import Boat from "../components/Pages/Index/Boat"
import About from "../components/Pages/Index/About"


export default ({ data }) => (
  <Layout className="index">
    <Seo title=""/>
    <Intro />
    <Blocks />
    <Portfolio websites={data.allMarkdownRemark.edges[0].node.frontmatter.website} logos={data.allMarkdownRemark.edges[1].node.frontmatter.logo}/>
    <Boat />
    <About />
  </Layout>
)

export const query = graphql`
  query {
    allMarkdownRemark(
        limit: 2000, 
        filter: {
            frontmatter: {
                pageKey: {
                    in: ["websites", "logos"]
                    }
                }
            }
        ) {
        edges {
            node {
                frontmatter {
                    website {
                        title
                        tags
                        technologies
                        link
                        backgroundMobile {
                            childImageSharp {
                                fluid(maxWidth: 3000, quality: 100) {
                                ...GatsbyImageSharpFluid
                                }
                            }
                        }
                        background { 
                            childImageSharp {
                                fluid(maxWidth: 4000, quality: 100) {
                                ...GatsbyImageSharpFluid
                                }
                            }
                        }
                        
                    }
                    logo {
                        title
                        link
                        background { 
                            childImageSharp {
                                fluid(maxWidth: 4000, quality: 100) {
                                ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                }
                
            }
        }
      }
  }
`